/* @flow */

import $ from 'jquery';
import 'magnific-popup';
import i18next from 'i18next';
import fetchUrl from '../../utils/fetch';

class RedirectError extends Error {
  newLocation: string

  constructor(newLocation: string) {
    super();
    this.newLocation = newLocation;
  }
}


const bindStoreStrategy = ($form) => {
  // Handling modal
  const $saveCardCheckbox = $form.find('#SaveCardCheckbox');
  const $changeStrategyModal = $('#ChangePaymentStoreStrategyModal');
  const $simpleStoreForm = $form.find('#SimplePaymentStore');
  const $fullStoreForm = $form.find('#FullPaymentStore');

  const hideAndSetStrategy = (strategy) => {
    $form
      .find(`input[name="strategy"][value="${strategy}"]`)
      .prop('checked', true);
    $.magnificPopup.close();
  };

  $changeStrategyModal
    .find('button.keep-strategy')
    .on('click', () => hideAndSetStrategy('removeOnceCompleted'));

  $changeStrategyModal
    .find('button.none-strategy')
    .on('click', () => hideAndSetStrategy('none'));

  // Handling buttons behavior
  $saveCardCheckbox.on('change', () => {
    $simpleStoreForm.remove();
    $fullStoreForm.show();

    $.magnificPopup.open({
      items: {
        src: $changeStrategyModal,
      },
    });
  });
};

export const setPaymentMethod = (method) => $('input[name="paymentMethod"]').val(method);

const getSubmit = ($form) => $form.find('button[type="submit"]');

const getError = ($form) => $form.find('#payment-errors');
const hideError = ($form) => getError($form).empty();

let isStripeFormOkay = false;

export const enablePaymentSubmit = ($form) => {
  const $submit = getSubmit($form);
  hideError($form);
  $submit.removeClass('disabled');
  $submit.prop('disabled', false);
};

export const disablePaymentSubmit = ($form) => {
  const $submit = getSubmit($form);
  $submit.addClass('disabled');
  $submit.prop('disabled', true);
};

export const enableSubmitIfPossible = ($form) => {
  if (isStripeFormOkay) {
    enablePaymentSubmit($form);
  } else {
    disablePaymentSubmit($form);
  }
};

function configurePaymentMethods($form: any) {
  if ($form.length === 0) {
    return;
  }

  const getStripeConnectAccount = () => $('input[name="stripeConnectAccount"]').val();
  const getTotal = () => $('input[name="total"]').val();
  const getCurrency = () => $('input[name="currency"]').val();
  const getLocale = () => $('input[name="locale"]').val();

  const stripe = window.Stripe(window.STRIPE_API_KEY);

  const stripeConnect = window.Stripe(window.STRIPE_API_KEY, {
    stripeAccount: getStripeConnectAccount(),
  });

  const elements = stripe.elements({
    mode: 'payment',
    amount: Math.round(getTotal() * 100),
    currency: getCurrency(),
    captureMethod: 'automatic',
    locale: getLocale(),
    appearance: {
      variables: {
        borderRadius: '20px',
        colorPrimary: '#1437b9',
        colorPrimaryText: '#1437b9',
        colorSuccess: '#04cab6',
        colorSuccessText: '#04cab6',
        colorDanger: '#ff436c',
        colorDangerText: '#ff436c',
      },
      rules: {
        '.Label': {
          margin: '0 14px 6px',
          fontWeight: 600,
          fontSize: '11px',
          letterSpacing: '1px',
          color: '#001875',
          textTransform: 'uppercase',
        },
        '.Input': {
          fontSize: '14px',
          color: '#050505',
          borderColor: '#b9c9d6',
        },
        '.Input::placeholder': {
          color: '#5d778a',
        },
      },
    },
  });

  // Mount Stripe component
  const card = elements.create('payment', {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: true,
    },
  });

  const $newPm = $form.find('#new-pm');
  const $existingPm = $form.find('#existing-pm');
  const $newCardForm = $form.find('#NewCard');
  const $monthlyPm = $form.find('#monthly-pm');

  const $submit = getSubmit($form);

  const getIsCurrentCardSelected = () => $existingPm.hasClass('active');

  const displayError = (error) => getError($form).html(`<p>${error.message ? error.message : error}</p>`);

  if ($form.find('#card-element').length) {
    card.mount('#card-element');
    card.addEventListener('change', (event) => {
      if (event.complete) {
        isStripeFormOkay = true;
        enablePaymentSubmit($form);
      } else if (event.error) {
        isStripeFormOkay = false;
        displayError(event.error);
        disablePaymentSubmit($form);
      }
    });
  }

  const setStripeCard = (name: string, value: string) => {
    const $tokenInput = $form.find(`input[name="${name}"]`);
    if ($tokenInput && $tokenInput.length) {
      $tokenInput.val(value);
    } else {
      // Adding token as hidden input and submit
      const hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', name);
      hiddenInput.setAttribute('value', value);
      $form.append(hiddenInput);
    }
  };

  const getCsrfToken = () => $('input[name="csrfToken"]').val();

  const getPaymentMethodId = () => {
    if (getIsCurrentCardSelected()) {
      return Promise.resolve($existingPm.attr('data-id'));
    }
    const holderName = $form.find('input[name="holderName"]').val();

    return stripe
      .createPaymentMethod('card', card, {
        billing_details: {
          name: holderName,
        },
      })
      .then(({ error, paymentMethod }) => {
        if (error) {
          throw Error(error && error.message ? error.message : error);
        } else {
          return paymentMethod.id;
        }
      });
  };

  const getPaymentIntent = (methodId) => {
    const gaClientId = $('#gaClientId').val();
    const creditsCount = $('input[name="creditsCount"]').val();
    const strategy = $('input[name="strategy"]:checked').val();
    const subscribeToNewsletter = $('input[name="subscribeToNewsletter"]:checked').val() === 'true';

    return fetchUrl('/cart/payment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Csrf-Token': getCsrfToken(),
      },
      body: JSON.stringify({
        methodId,
        gaClientId,
        creditsCount,
        strategy,
        subscribeToNewsletter,
      }),
    })
      .then((res) => {
        if (res.redirected) {
          throw new RedirectError(res.url);
        }
        return res.json();
      })
      .catch((err) => {
        if (err instanceof RedirectError) {
          throw err;
        }
        return (
          err.res?.json().then((body) => {
            throw new Error(body.error);
          })
        );
      });
  };

  const pay = () => {
    getPaymentMethodId()
      .then((methodId) => (
        getPaymentIntent(methodId).then(({ intent_secret: intentSecret, method_id: methodId }) => (
          stripeConnect.handleCardPayment(intentSecret, {
            payment_method: methodId,
          })
        ))
      ))
      .then((res) => {
        if (res.error) {
          throw new Error(res.error.message);
        }
        return document.location.replace(
          `/cart/payment/waiting?intent_secret=${res.paymentIntent.client_secret}`,
        );
      })
      .catch((err) => {
        if (err instanceof RedirectError) {
          document.location.replace(err.newLocation);
        } else {
          $submit.removeClass('loading');
          $submit.prop('disabled', false);
          displayError(err);
        }
      });
  };

  const confirm = () => {
    $form.attr('method', 'POST');
    $form.attr('action', '/cart/confirm');
    $form.submit();
  };

  // Handling submit
  $submit.on('click', (event) => {
    event.preventDefault();
    const acceptCGU = $('input[name="acceptCGU"]:checked').val() === 'true';
    if (!acceptCGU) {
      displayError(i18next.t('error_accept_cgu'));
      return;
    }

    hideError($form);

    $submit.addClass('loading');
    $submit.prop('disabled', true);

    // active should be checked differently maybe
    const method = $('input[name="paymentMethod"]').val();
    switch (method) {
      case 'credits':
      case 'monthly':
        confirm();
        break;
      default:
        pay();
        break;
    }
  });

  const enablePayment = () => {
    const token = $existingPm.attr('data-id');
    if (token && token.length) {
      // Adding stripe id input
      setStripeCard('stripeId', token);
      enablePaymentSubmit($form);

      // Clearing Stripe element
      card.clear();
    }
    if ($monthlyPm && $monthlyPm.hasClass('active')) {
      enablePaymentSubmit($form);
    }
  };

  enablePayment();

  // Handling change
  $newPm.on('click', (event) => {
    event.stopPropagation();

    $existingPm.removeClass('active');
    $newPm.addClass('active');
    $newCardForm.show();

    disablePaymentSubmit($form);
  });

  // Handling select
  $existingPm.on('click', (event) => {
    event.stopPropagation();

    if (!getIsCurrentCardSelected()) {
      $existingPm.addClass('active');
      $newPm.removeClass('active');
      $newCardForm.hide();
      enablePayment();
    }
  });

  bindStoreStrategy($form);
}

export function bindPaymentMethods($form: any) {
  if (window.Stripe) {
    configurePaymentMethods($form);
  } else {
    document.querySelector('#stripe-js').addEventListener('load', () => {
      configurePaymentMethods($form);
    });
  }
}
